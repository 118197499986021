import { useStore } from '@nanostores/react';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { $notificationContent } from '../../stores/notificationStore';
import type { RawPortableText } from '../../types/types';
import * as styles from './NotificationContainer.module.scss';

function renderNotificationPortableText(portableText: RawPortableText, fileUrl?: string) {
  return portableText.map((block, index) => {
    if (block._type === 'block') {
      return (
        <p key={index}>
          {block.children.map((child, childIndex) => {
            if (child._type === 'span') {
              return (
                <span key={childIndex}>
                  {child.marks.includes('linkToDownloadFile') ? (
                    <a href={fileUrl} download>
                      {child.text}
                    </a>
                  ) : (
                    child.text
                  )}
                </span>
              );
            }
          })}
        </p>
      );
    }
  });
}

const NotificationContainer: React.FC = () => {
  const notificationContent = useStore($notificationContent);

  if (!notificationContent) {
    return null;
  }

  return (
    <div id="notification-container" className={styles.notificationContainer}>
      <div className={styles.notification}>
        <FaCheckCircle className={styles.notificationIcon} />
        <p>
          {typeof notificationContent === 'string'
            ? notificationContent
            : renderNotificationPortableText(
                notificationContent.portableText,
                notificationContent.fileUrl,
              )}
        </p>
      </div>
    </div>
  );
};

export default NotificationContainer;
